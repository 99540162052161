import { notification } from 'antd' //message,
import axios from 'axios'
import { createBrowserHistory } from 'history'
import { clear, getAlls } from 'utils/IDBStorage'
import { TABLE_USER } from 'utils/IDBStorage/type'
import { getLocalStorage } from 'utils/localStorage'
import dataCode from 'common/constants/codeError.json'
import { UrlInternal } from 'common/constants/endpoints'

const jwt = require('jsonwebtoken')
const history = createBrowserHistory()
// const { REACT_APP_API_SERVER } = process.env;

export const Axios = axios.create({
  // baseURL: REACT_APP_API_SERVER,
  baseURL: 'https://api.gold-casino888.com/api/v1'
  // baseURL: 'https://api.bigpeakgame.com/api/v1'
})

// eslint-disable-next-line no-underscore-dangle
const _axios = axios.create({
  // baseURL: REACT_APP_API_SERVER,
  baseURL: 'https://api.gold-casino888.com/api/v1'
  // baseURL: 'https://api.bigpeakgame.com/api/v1'
})

const mainAxios = {
  request: async (parameter: any) => {
    const {
      methodType,
      url,
      payload,
      requiresToken,
      config,
      notError,
      getError,
      params
    } = parameter
    const userData: any = await localStorage.getItem('User')

    return new Promise((resolve, reject) => {
      // axios request default options
      const headers = config && config.headers ? config.headers : {}

      if (headers.contentType) {
        headers['Content-Type'] = headers.contentType
        delete headers.contentType
      } else {
        headers['Content-Type'] = 'application/json'
      }

      // if API endpoint requires a token
      if (requiresToken) {
        const acToken: any = JSON.parse(userData)

        if (!userData) {
          localStorage.removeItem('User')
          localStorage.removeItem('Popup')
          // notification.open({
          //   message: '기능을 사용하기 위해 로그인해 주십시오',
          //   placement: 'bottomLeft'
          // })
        }
        // if (acToken?.value) acToken = acToken.value;
        if (acToken) headers['Authorization'] = `Bearer ${acToken?.value}`
      }

      _axios
        .request({
          url,
          method: methodType,
          data: payload,
          headers,
          params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          if (err.response) {
            // console.log("fdsaf==>", err.response.status);
            if (err.response.status === 401) {
              // let acToken: any = getAlls(TABLE_USER);
              // if (acToken?.accessToken) {
              //   clear(TABLE_USER);
              //   window.location.reload();
              // }
              localStorage.removeItem('User')
              localStorage.removeItem('Popup')
              // notification.open({
              //   message: "token expires",
              //   placement: "bottomRight",
              // });
              window.location.reload()
            } else {
              if (!notError) {
                const data: any = dataCode
                const errData = err.response.data
                // message.info(data[errData.errorCode?.trim()]);
                notification.open({
                  message: data[errData.errorCode?.trim()] + '.',
                  placement: 'bottomRight'
                })
                resolve({ data: { datas: [] } })
              } else {
                if (getError) {
                  resolve({ data: { datas: [] }, error: err.response.data })
                }
              }
            }
          }
          reject(err)
        })
    })
  },

  getRequest: async function (parameter: any) {
    parameter.methodType = 'GET'
    return this.request(parameter)
  },

  postRequest: async function (parameter: any) {
    parameter.methodType = 'POST'
    return this.request(parameter)
  },

  putRequest: async function (parameter: any) {
    parameter.methodType = 'PUT'
    return this.request(parameter)
  },

  patchRequest: async function (parameter: any) {
    parameter.methodType = 'PATCH'
    return this.request(parameter)
  },

  deleteRequest: async function (parameter: any) {
    parameter.methodType = 'DELETE'
    return this.request(parameter)
  }
}

export { mainAxios }
